var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "todo" }, [
    _c("div", { staticClass: "todo" }, [
      _c("div", { staticClass: "todo__top" }, [
        _c(
          "div",
          {
            staticClass: "todo__name",
            class: { "is-line-through": _vm.todo.status === "done" },
            attrs: { title: _vm.todo.name },
          },
          [
            _vm.todo.todoType === "visit"
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    class: { isExternal: _vm.todo.type === "external" },
                  },
                  [_vm._v("group")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.todo.todoType === "phone"
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    class: { isExternal: _vm.todo.type === "external" },
                  },
                  [_vm._v("call")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.todo.todoType || _vm.todo.todoType === "general"
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    class: { isExternal: _vm.todo.type === "external" },
                  },
                  [_vm._v("list")]
                )
              : _vm._e(),
            _vm._v(
              "\n                " + _vm._s(_vm.todo.name) + "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "level-left" },
          [
            _c("deadline", {
              staticClass: "level-item",
              attrs: { editable: "" },
              model: {
                value: _vm.todo.dueDate,
                callback: function ($$v) {
                  _vm.$set(_vm.todo, "dueDate", $$v)
                },
                expression: "todo.dueDate",
              },
            }),
            _vm._v(" "),
            _c(
              "simple-menu",
              {
                attrs: {
                  icon: "more_vert",
                  material: "",
                  buttonWrapper: false,
                },
              },
              [
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeStatus("open")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("radio_button_unchecked"),
                    ]),
                    _vm._v(
                      "\n                        offen\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeStatus("waiting")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("pause"),
                    ]),
                    _vm._v(
                      "\n                        wartend\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeStatus("done")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check"),
                    ]),
                    _vm._v(
                      "\n                        erledigt\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("edit", _vm.todo)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("edit"),
                    ]),
                    _vm._v(
                      "\n                        bearbeiten\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.todo.todoType === "visit" &&
                _vm.todo.order &&
                _vm.$listeners.addClientVisit
                  ? _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("addClientVisit", _vm.todo)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("description"),
                        ]),
                        _vm._v(
                          "\n                        Kundenbericht\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level" },
        [
          _c(
            "div",
            { staticClass: "todo__info" },
            [
              _vm.noOrder === false
                ? _c(
                    "popover",
                    {
                      attrs: {
                        trigger: "hover",
                        disabled: !_vm.popoverPossible,
                      },
                    },
                    [
                      _vm.todo.order
                        ? _c(
                            "a",
                            {
                              staticClass: "tag is-white",
                              attrs: {
                                href: `/orders/${_vm.todo.order.id}`,
                                target: "_blank",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-folder-open",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.todo.order.id) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("template", { slot: "popover" }, [
                        _c(
                          "div",
                          { staticClass: "todo__popover" },
                          [
                            _vm.todo.order
                              ? [
                                  _c("ul", { staticClass: "icon-list" }, [
                                    _c("li", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("person")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.todo.order.client.companyName
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("date_range")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("fullYear")(
                                              _vm.todo.order.startAt
                                            )
                                          ) +
                                            " - " +
                                            _vm._s(
                                              _vm._f("fullYear")(
                                                _vm.todo.order.endAt
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("flag")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.tripDestination(_vm.todo.order)
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("sync")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.todo.order.trip.name)
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("hr"),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "nl2br",
                              domProps: {
                                innerHTML: _vm._s(_vm.todo.description),
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "buttons" },
                              _vm._l(_vm.todo.tags, function (tag) {
                                return _c(
                                  "div",
                                  {
                                    key: tag,
                                    staticClass: "button is-small",
                                    attrs: { title: tag },
                                  },
                                  [_vm._v(_vm._s(_vm._f("truncate")(tag)))]
                                )
                              }),
                              0
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.todo.status === "waiting"
                ? _c("span", { staticClass: "tag" }, [
                    _c("i", { staticClass: "fa fa-pause no-margin fa-sm" }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "is-truncated" }, [
                _vm.clientName
                  ? _c("span", { staticClass: "tag" }, [
                      _vm._v(_vm._s(_vm._f("truncate")(_vm.clientName))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.destinations
                  ? _c("span", { staticClass: "tag" }, [
                      _vm._v(_vm._s(_vm._f("truncate")(_vm.destinations))),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.todo.user && _vm.showUser
            ? _c("user-image", { attrs: { user: _vm.todo.user } })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }