<template>
    <div ref="todo">
        <div class="todo">
            <div class="todo__top">

                <div
                    class="todo__name"
                    v-bind:class="{'is-line-through': todo.status === 'done'}"
                    v-bind:title="todo.name"
                >
                    <i v-if="todo.todoType === 'visit'" class="material-icons" :class="{'isExternal': todo.type === 'external'}">group</i>
                    <i v-if="todo.todoType === 'phone'" class="material-icons" :class="{'isExternal': todo.type === 'external'}">call</i>
                    <i v-if="(!todo.todoType || todo.todoType === 'general')" class="material-icons" :class="{'isExternal': todo.type === 'external'}">list</i>
                    {{ todo.name }}
                </div>

                <div class="level-left">
                    <deadline v-model="todo.dueDate" editable class="level-item"/>

                    <simple-menu icon="more_vert" material v-bind:buttonWrapper="false">
                        <button v-on:click.prevent="changeStatus('open')">
                            <i class="material-icons">radio_button_unchecked</i>
                            offen
                        </button>
                        <button v-on:click.prevent="changeStatus('waiting')">
                            <i class="material-icons">pause</i>
                            wartend
                        </button>
                        <button v-on:click.prevent="changeStatus('done')">
                            <i class="material-icons">check</i>
                            erledigt
                        </button>
                        <hr>
                        <button v-on:click.prevent="$emit('edit', todo)">
                            <i class="material-icons">edit</i>
                            bearbeiten
                        </button>
                        <button v-if="todo.todoType === 'visit' && todo.order && $listeners.addClientVisit" v-on:click.prevent="$emit('addClientVisit', todo)">
                            <i class="material-icons">description</i>
                            Kundenbericht
                        </button>
                    </simple-menu>
                </div>
            </div>

            <div class="level">
                <div class="todo__info">
                    <popover trigger="hover" v-bind:disabled="!popoverPossible" v-if="noOrder === false">
                        <a class="tag is-white" v-if="todo.order" v-bind:href="`/orders/${todo.order.id}`" target="_blank">
                            <i aria-hidden="true" class="fa fa-folder-open"></i>
                            {{ todo.order.id }}
                        </a>

                        <template slot="popover">
                            <div class="todo__popover">
                                <template v-if="todo.order">
                                    <ul class="icon-list">
                                        <li>
                                            <i class="material-icons">person</i>
                                            <span>{{ todo.order.client.companyName }}</span>
                                        </li>
                                        <li>
                                            <i class="material-icons">date_range</i>
                                            <span>{{ todo.order.startAt | fullYear }} - {{ todo.order.endAt | fullYear }}</span>
                                        </li>
                                        <li>
                                            <i class="material-icons">flag</i>
                                            <span>{{ tripDestination(todo.order)  }}</span>
                                        </li>
                                        <li>
                                            <i class="material-icons">sync</i>
                                            <span>{{ todo.order.trip.name }}</span>
                                        </li>
                                    </ul>
                                    <hr>
                                </template>
                                <p v-html="todo.description" class="nl2br"></p>
                                <div class="buttons">
                                    <div
                                        class="button is-small"
                                        v-for="tag in todo.tags"
                                        v-bind:key="tag"
                                        v-bind:title="tag"
                                    >{{tag | truncate}}</div>
                                </div>
                            </div>
                        </template>
                    </popover>

                    <span class="tag" v-if="todo.status === 'waiting'"><i class="fa fa-pause no-margin fa-sm"></i></span>

                    <span class="is-truncated">
                        <span class="tag" v-if="clientName">{{clientName | truncate}}</span>
                        <span class="tag" v-if="destinations">{{destinations | truncate}}</span>
                    </span>
                </div>

                <user-image v-if="todo.user && showUser" v-bind:user="todo.user"/>
            </div>
        </div>
    </div>
</template>



<script>
import axios from 'axios';
import anime from 'animejs';

import { notifySuccess, notifyError } from '@components/Notification';
import SimpleMenu from '@components/SimpleMenu';
import Popover    from '@components/Popover';
import Deadline   from '@components/Deadline';
import UserImage  from '@components/UserImage';

import { editTodo }       from '@api';
import { deadlineUrgency, fullYear, dateISOtoView, dateViewtoISO, capitalize }  from '@utilities/functions';
import { store }                      from '@orders/store';


export default {
    store,


    props: {
        todo:    { type: Object, required: true },
        noOrder: { type: Boolean, default: false },
        showUser: { type: Boolean, default: true}
    },


    computed: {
        today: function () {
            return dateISOtoView((new Date()).toISOString()) + '00:00:00'
        },

        popoverPossible: function () {
            return !!this.todo.order || this.todo.description || this.todo.tags.length > 0
        },

        clientName: function () {
            if (!!this.todo.order && !!this.todo.order.client) {
                return this.todo.order.client.shortName
            } else {
                return false;
            }
        },

        destinations: function () {
            if (!!this.todo.order && !!this.todo.order.trip && this.todo.order.trip.destinations.length > 0) {
                return this.todo.order.trip.destinations.map(destination => destination.name).join(', ')
            } else {
                return false
            }
        }
    },


    data: function () {
        return {
            isOpen: false,
        }
    },


    methods: {
        changeStatus: function (newStatus) {
            if (newStatus === this.todo.status)
                return;

            const done = (newStatus === 'done');

            this.$emit('loading', true);

            Promise.all([
                this.disappear(done ? -1 : 1),
                this.editTodo(this.todo, {
                    doneDate: (done ? this.today : null),
                    status: newStatus,
                })
            ]).then(([_, response]) => {
                this.$store.commit('updateTodo', response.data);
                /*if(response.data.orderRequest && response.data.orderRequest.id){
                    this.$store.commit('updateRequestOptionTodo', {requestID: response.data.orderRequest.id, optionTodo: response.data})
                }*/
                notifySuccess('Todo-Status geändert!');

            }, error => { notifyError('Der Todo konnte nicht aktualisiert werden! Server Error!'); })
            .then(() => { this.$emit('loading', false); });
        },


        editTodo: function (todo, data = {}) {

            return new Promise(function (resolve, reject) {
                editTodo(
                    {
                        id: todo.id,
                        data,
                    }, {
                        _groups: ['order_request_list_read']
                    }
                ).then(response => {
                    resolve(response)
                }, error => reject(error))
            })
        },


        disappear: function (direction = 1) {
            const targets = this.$refs.todo;

            return new Promise(function (resolve, reject){
                anime({
                    targets,
                    easing: 'easeInQuart',
                    duration: 400,
                    opacity: [1,0],
                    translateX: String(direction * 100) + '%',
                    complete: function () {
                        resolve();
                        anime({
                            targets,
                            easing: 'linear',
                            duration: 250,
                            height: 0,
                            complete: function () {
                                resolve();
                            }
                        })
                    }
                })
            });
        },


        tripDestination: function (order) {
            return order.trip && order.trip.destinations.length > 0 ? order.trip.destinations.map(({name}) => name).join(', ') : false;
        },


        deadlineUrgency
    },


    watch: {
        'todo.dueDate': function (newValue, oldValue) {
            if (newValue !== oldValue && !this.todo.justSaved) {
                this.editTodo(this.todo, {
                    dueDate: newValue,
                    ...!!this.todo.request && { request: this.todo.request },
                }).then(response => {
                    /*if(response.data.orderRequest && response.data.orderRequest.id){
                        this.$store.commit('updateRequestOptionTodo', {requestID: response.data.orderRequest.id, optionTodo: response.data})
                    }*/

                    notifySuccess('Todo wurde aktualisiert!');
                    this.$store.commit('updateTodo', response.data);
                });
            }
            if(this.todo.justSaved){
                this.todo.justSaved = false;
            }
        }
    },


    filters: {
        truncate: function (string) {
            if (string.length > 15) {
                return string.substring(0, 15) + '...';
            }

            return string;
        },


        fullYear
    },


    components: {
        Deadline,
        UserImage,
        SimpleMenu,
        Popover
    }
}
</script>
